import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../../../components/Layout"
import "bootstrap/dist/css/bootstrap.min.css"
import { Container, Nav, Card, CardImg, CardProps, CardImgProps } from "react-bootstrap"
import Prodopt from "../../../components/Prodopt"
import Prodfeat from "../../../components/Prodfeat"



const FDP = () => (
    <Layout>
        <Container>
            <div className="column">
                <br /><br />
                <div class="text-center">
                    {/* Картинка */}
                    <StaticImage
                        src="img/637399141669652072-b90-fdp--144-955-623.png"
                        width={790}
                        quality={50}
                        placeholder="blurred"
                        formats={["auto", "webp", "avif"]}
                        alt="FDP"
                    />
                </div>
                {/* Заголовок */}
                <h1 class="text-center">FDP - глубокорыхлитель</h1>
                {/* Текст */}
                <p>Серия FDP глубокорыхлителей могут достигать 60 см рабочей глубины при меньщей мощности из-за изогнутых зубьев. Также изогнутые зубья позволяют равномерно рыхлить почву по рабочей ширине.</p>
                {/* Преимущества */}

                <div class="row">
                    <div class="col-sm-6 col-lg-4 mt-4"> <div class="h-100 position-relative"> <div class="card-body border-dark"><StaticImage src="img/637399150544662199-b75----550-0.jpeg" width={392} quality={50} formats={["auto", "webp", "avif"]} alt="FDP. Колесо Регулировки Глубины" style={{ marginBottom: `1.45rem` }} /><strong class="h4">Колесо Регулировки Глубины</strong><p class="h5"></p> </div> </div> </div>
                    <div class="col-sm-6 col-lg-4 mt-4"> <div class="h-100 position-relative"> <div class="card-body border-dark"><StaticImage src="img/637399152323577047-b75----550-0.jpeg" width={392} quality={50} formats={["auto", "webp", "avif"]} alt="FDP. Двойной Ролик (Опционально)" style={{ marginBottom: `1.45rem` }} /><strong class="h4">Двойной Ролик (Опционально)</strong><p class="h5"></p> </div> </div> </div>
                    <div class="col-sm-6 col-lg-4 mt-4"> <div class="h-100 position-relative"> <div class="card-body border-dark"><StaticImage src="img/637399151529821510-b75---550-0.jpeg" width={392} quality={50} formats={["auto", "webp", "avif"]} alt="FDP. Стальные Ножки" style={{ marginBottom: `1.45rem` }} /><strong class="h4">Стальные Ножки</strong><p class="h5"></p> </div> </div> </div>

                </div>
                <h2 class="text-center py-3 text-black-50">
                    ТЕХНИЧЕСКИЕ СПЕЦИФИКАЦИИ
                </h2>
                {/* Таблица */}
                <div class="table-responsive">
                    <table class="table table-striped table-hover table-bordered">
                    <thead class="background-dark-gray-ozdo">
                    <tr>
                            <th>МОДЕЛЬ</th>
                            <th>КОЛИЧЕСТВО ЛАП</th>
                            <th>РАССТОЯНИЕ <br />МЕЖДУ ЛАПАМИ</th>
                            <th>РАЗМЕРЫ <br />ОСНОВНОГО ШАССИ</th>
                            <th>ВЕС</th>
                            <th>ДЛИНА</th>
                            <th>ВЫСОТА</th>
                            <th>ШИРИНА</th>
                            <th>РАБОЧАЯ ШИРИНА</th>
                            <th colspan="2" align="center">ТРЕБУЕМАЯ МОЩНОСТЬ</th>
                        </tr>
                        <tr>
                            <th></th>
                            <th>шт.</th>
                            <th>см</th>
                            <th>см</th>
                            <th>кг</th>
                            <th>см</th>
                            <th>см</th>
                            <th>см</th>
                            <th>см</th>
                            <th>л.с.</th>
                            <th>кВт</th>
                        </tr>
                        </thead><tbody class="align-middle">
                        <tr>
                            <td>FDP-10</td>
                            <td>10</td>
                            <td rowspan="4" class="align-middle" align="center">50</td>
                            <td rowspan="4" class="align-middle" align="center">120x 120 x 8</td>
                            <td>3050</td>
                            <td>250</td>
                            <td rowspan="4" class="align-middle" align="center">170</td>
                            <td>550</td>
                            <td>530</td>
                            <td>240-260</td>
                            <td>179-194</td>
                        </tr>
                        <tr>
                            <td>FDP-12</td>
                            <td>12</td>
                            <td>3755</td>
                            <td>280</td>
                            <td>650</td>
                            <td>630</td>
                            <td>300-350</td>
                            <td>224-261</td>
                        </tr>
                        <tr>
                            <td>FDP-OM-10</td>
                            <td>10</td>
                            <td>3920</td>
                            <td>300</td>
                            <td>550</td>
                            <td>530</td>
                            <td>240-260</td>
                            <td>179-194</td>
                        </tr>
                        <tr>
                            <td>FDP-OM-12</td>
                            <td>12</td>
                            <td>4500</td>
                            <td>330</td>
                            <td>650</td>
                            <td>630</td>
                            <td>300-350</td>
                            <td>224-261</td>
                        </tr></tbody></table>
                </div>
            </div>
        </Container>
    </Layout>
);

export default FDP;